export default class StaticTexts {

  static aboutUsTexts = {
    EN: {
      Serve: {
        First: `As Amerikadaniste, we have been providing uninterrupted service for 17 years in bringing all kinds of products from fashion to technology, from decoration products to kitchen utensils, from electronics to collection products from US to Turkey.`,
        Second: `From business people to community life, from artists to athletes, we deliver products that are not sought in Turkey but cannot be found in Turkey, in an easy, economical and trouble-free way.`,
        Third: `Offering a privileged end-to-end shopping experience at order, transportation and delivery points, Amerikadaniste also runs brand partnership programs with many banks and payment systems.`
      },
      Shopping: {
        First: `Amerikadaniste delivers your orders of clothing, shoes, bags, electronic parts, car parts, accessories, books, CDs, DVDs and all the products you want to buy, from the distinguished brands of US, to your home quickly and easily.`,
        Second: `Amerikadaniste offers an easy-to-use, privileged shopping experience for students and professionals who have lived in US before, Turkish companies doing business in US, or our members who cannot give up brands in US, where they can follow every step of their orders.`
      },
      Legrin: {
        First: `Legrin LLC. In 2003, was founded by two Turkish entrepreneurs who met during their M.B.A. education. The company, which successfully served 50,000 users with the online food ordering portal (HobokenDelivery.com) that they first developed for the city of Hoboken, NJ, sold the operations of this site to the industry's leading company Delivery.com in 2005.`,
        Second: `Amerikadaniste was established in October 2006 in US, which makes it possible to shop from US. Amerikadaniste provides its members with mail forwarding service from the USA and delivers all kinds of products that its members want to buy from the USA to their addresses in Turkey in an easy, fast and reliable way.`,
        Third: `Amerikadaniste, the first and leading mail forwarding service from US to Turkey; Today, continues to expand its vision of exporting services from Turkey to the world with its Boxinus and Shippn projects.`
      }
    },
    TR: {
      Serve: {
        First: `Amerikadaniste olarak modadan teknolojiye, dekorasyon ürünlerinden mutfak gereçlerine, 
                elektronik eşyalardan koleksiyon ürünlerine kadar her türlü ürünün Amerika’dan Türkiye’ye getirilmesinde 17 yıldır kesintisiz hizmet sunuyoruz.`,
        Second: `İş insanlarından cemiyet hayatına, sanatçılardan sporculara kadar birbirinden değerli üyelere Türkiye’de arayıp da bulamadıkları ürünleri kolay, ekonomik ve 
                sorunsuz bir şekilde Türkiye'ye ulaştırıyoruz.`,
        Third: `Sipariş, taşıma ve teslimat noktalarında uçtan uca ayrıcalıklı bir alışveriş 
                deneyimi sunan Amerikadaniste ayrıca birçok banka ve ödeme sistemleri ile marka ortaklığı programı yürütmektedir.`
      },
      Shopping: {
        First: `Amerikadaniste, Amerika'nın seçkin markalarından giyim, ayakkabı, çanta, 
                elektronik parça, araba parçası, aksesuar, kitap, CD, DVD ve satın almak istediğiniz tüm ürün siparişlerinizi hızlı ve kolayca evinize kadar teslim eder.`,
        Second: `Amerikadaniste, Amerika'da daha önce yaşamış olan öğrenci ve profesyoneller, Amerika'yla ticaret yapan Türk firmaları ya da Amerika’daki markalardan 
                vazgeçemeyen üyelerimiz için kullanımı kolay, siparişlerinin her adımını takip edebildikleri ayrıcalıklı bir alışveriş deneyimi sunar.`
      },
      Legrin: {
        First: `Legrin LLC. 2003 yılında New York'ta M.B.A. eğitimleri sırasında tanışan iki Türk girişimci tarafından kurulmuştur. 
                İlk olarak Hoboken, NJ şehri için geliştirdikleri online yemek siparişi portalıyla (HobokenDelivery.com) 50,000 kullanıcıya 
                başarıyla hizmet veren şirket bu sitenin operasyonlarını 2005 yılında sektörün lider şirketi Delivery.com'a satmıştır.`,
        Second: `2006 yılının Ekim ayında Amerika'dan kolay alışveriş yapmayı mümkün kılan 
                Amerikadaniste kurulmuştur. Amerikadaniste, üyelerine Amerika'dan posta 
                yönlendirme hizmeti sunarak üyelerinin Amerika'dan satın almak istedikleri her türlü ürünü kolay, hızlı ve güvenilir şekilde 
                Türkiye'deki adreslerine teslim etmektedir.`,
        Third: `Amerika'dan Türkiye'ye ilk ve lider posta yönlendirme hizmeti olan 
                Amerikadaniste; bugün ayrıca Boxinus ve Shippn projeleriyle  de Türkiye'den 
                dünyaya hizmet ihraç eden vizyonunu büyütmeye devam etmektedir.`
      }
    }
  };

  static pricesTexts = {
    TR: {
      First: ` Satın almak istediğiniz ürünlerin tahmini ağırlıklarını gösteren aşağıdaki listeyi sizin için hazırladık.`,
      Second: ` Detaylı bilgi için Üye Hizmetleri departmanımıza <a href="tel:+902122323397">0212 232 33 97</a> 
            numaralı telefondan ya da <a href="/contact" target="_blank">buradan</a> ulaşabilirsiniz.`,
      Third: ` Satın almayı düşündüğünüz ürünlere ait ürün linklerini <a href="/contact" target="_blank">iletişim formuyla</a>  
            bize iletirseniz, oluşacak maliyetler hakkında bilgi verebiliriz.`
    },
    EN: {
      First: ` We have prepared the following list for you, showing the estimated weights of the products you want to buy.`,
      Second: ` For detailed information, please contact with our Member Services department on<a href="tel:+902122323397"> +90 212 232 33 97</a> 
            or <a href="/contact" target="_blank">click here.</a>`,
      Third: ` If you send us the product links of the products that you consider purchasing, with the <a href="/contact" target="_blank">contact form</a> 
            we can give information about the costs that will occur.`
    }
  };

  static welcomeTexts = {
    TR: {
      InfoText: `Bu adresi yapacağınız her türlü <strong>alışverişinizde</strong> "Teslimat Adresi"
            (Shipping Address) olarak kullanabilirsiniz.`
    },
    EN: {
      InfoText: `You can use this address as your "Shipping Address" in every kind of <strong>shopping</strong> of yours.`
    }
  };

  static signupTexts = {
    TR: {
      UserAgreementText: `<a>Kullanıcı Sözleşmesi</a>'ni okudum, kabul ediyorum.`,
      UserAgreementContent: [
        {
          id: 0,
          text: `Amerikadaniste.com kullanıcılarına paketlerini gönderebilecekleri bir Amerika adresi verir. Bu adres Clifton, NJ'dedir.`
        },
        {
          id: 1,
          text: `Amerikadaniste.com kullanıcılarının paketlerini yukarıda belirtilen adresten alır, kullanıcının talebine göre düzenler, paketlerini birleştirir ve kullanıcının ülkesindeki adresine o ülkenin iş saatleri içinde gönderir.`
        },
        {
          id: 2,
          text: `Amerikadaniste.com kullanıcısı, paketin alınması ve gönderilmesi sırasında doğacak her türlü vergi, gümrük vergisi, servis bedeli, taşıma bedeli gibi ücretleri ödemekle yükümlüdür.`
        },
        {
          id: 3,
          text: `Amerikadaniste.com kullanıcısı internet sitesi ve mobil uygulamadan aldığı servisler için doğacak her türlü servis bedelinin kredi kartından tahsili için Legrin LLC'yi yetkili kılar. `
        },
        {
          id: 4,
          text: `Gönderim bedeli, paketin ağırlığına ve hacmine göre hesaplanır. Kullanıcı "Paketi Gönder" talebi yapmadan önce internet sitesi ve mobil uygulamadan kullanıcıya bildirilir.`
        },
        {
          id: 5,
          text: `Amerikadaniste.com, kullanıcılarının paketlerini 30 gün süre ile ücretsiz depolar. 30 günü aşan depolamalarda 75 güne kadar pound (lbs) başına $2.5 ücret alınır. Paket depolama toplamda 75 günü aşamaz. Amerikadaniste.com, 75 günden daha fazla bekleyen paketleri kullanıcıya 75 gün aşımını ihbar ettikten 5 iş günü sonra imha etme hakkına sahiptir. 30 gün ücretsiz depolama süresini aşan paketlerin depolama ücreti ödenmeden kargo siparişi verilemeyecektir.`
        },
        {
          id: 6,
          text: ` Kargo ücretleri paketlerin ağırlığına göre hesaplanır. Büyük ve hafif paketlerin kargo ücretleri hesaplanırken hacimsel ağırlık göz önüne alınabilir. Hacimsel ağırlık; paketin en, boy ve yükseklik ölçülerinin çarpılıp 139'a bölünmesiyle elde edilen değerdir. Hacimsel ağırlık, terazi ağırlığından büyükse paketin kargo ücreti hacimsel ağırlıktan hesaplanır.`
        },
        {
          id: 7,
          text: `Paket sigortalama bedeli, paketin bildirilen değerine göre hesaplanır. Sigorta bedelleri, kullanıcı "Paketi Gönder" talebi yapmadan önce internet sitesi ve mobil uygulamadan kullanıcıya bildirilir. `
        },
        {id: 8, text: `Amerikadaniste.com (Legrin LLC) teslim aldığı her paketi güvenlik kuralları nedeniyle açar, inceler ve denetler. `},
        {
          id: 9,
          text: `Her iki taraf da bu sözleşmeyi, yazılı olarak bildirmek koşuluyla (elektronik posta, faks ya da posta yoluyla) iptal etme hakkına sahiptir. İptal edilen yıllık üyeliklerin kullanılmamış aylar için üyelik bedelleri, aylık $6 tutarından hesaplanarak iade edilir. İade işlemi 10 iş günü içinde tamamlanır.`
        },
        {
          id: 10,
          text: `Aylık üyelik seçeneğiyle üye olmuş kullanıcıların üyeliklerini iptal etmeleri durumunda aylık üyelik bedeli iade edilmez. Amerikadaniste.com üyeliğini iptal eden kullanıcıların posta kutularındaki paketlerini satıcılara geri gönderme ya da imha etme hakkına sahiptir. Üyeliğini iptal etmiş kullanıcılar için kargo ücretleri sitede yayınlanan kargo ücretlerinin iki katı olacak şekilde uygulanır. `
        },
        {
          id: 11,
          text: `Kullanıcı satın aldığı Amerikadaniste.com servisleri için 30 günden daha fazla süre ödeme yapmaz ise Amerikadaniste.com üyeliği iptal etme hakkını saklı tutar. Amerikadaniste.com, bu kullanıcıların posta kutularındaki paketlerini satıcılara geri gönderme ya da imha etme hakkına sahiptir. Bu durumda kullanıcı posta kutusundaki paketlerin geri gönderimi ya da imhası ile ilgili hiçbir hak iddia edemez.`
        },
        {
          id: 12,
          text: `Aylık ya da yıllık üyelikler, kullanıcı yazılı olarak üyelik değişikliği ya da iptali talep etmediği sürece otomatik olarak yenilenir. Aylık üyelik bedeli $9 veya yıllık üyelik bedeli $75 olarak tahsil edilir.`
        },
        {
          id: 13,
          text: `Gecikme bedelleri: Ödeme tarihinden 30 gün geçen ödenmemiş servis ücretleri için $10 ve aylık %8 gecikme faizi uygulanır. Bu faiz bedeli, ödemenin geciktiği her ay için tahsil edilecektir. `
        },
        {
          id: 14,
          text: `Amerikadaniste.com üyeliği, site üzerinden üyelik kaydı yapıldığı an başlar. Amerika adresinin kullanıcı tarafından kullanılması bu kullanım şartlarının kabul edildiğini ve sitede verilen her türlü hizmet için tahsil edilecek hizmet bedellerinin kullanıcının kayıtlı kredi kartından yapılabileceğini gösterir.`
        },
        {
          id: 17,
          text: `Amerikadaniste.com kullanıcısı, Amerikadaniste.com'a üye olarak, kayıtlı e-posta adresine pazarlama, kampanya, bilgilendirme ve hizmetle ilgili her türlü iletişimin elektronik ileti ve SMS olarak gönderileceğini kabul eder. Kullanıcı isterse, Amerikadaniste.com hesabına giriş yaptıktan sonra "Hesabım" sayfasından ”Alışveriş bülteni istiyorum" ve/veya "SMS istiyorum" kutucuklarındaki tikleri kaldırarak değişiklik yapabilir ya da Amerikadaniste.com'a email ya da telefonla tercihini iletebilir.`
        },
        {
          id: 16,
          text: `Amerikadaniste.com kullanıcısı hem Amerika'nın hem de kendi ülkesinin belirlediği bu siteden aldığı servisle ilgili olabilecek tüm kanunlara uymakla yükümlüdür. Amerikadaniste.com kullanıcısı hiçbir şekilde bu siteyi, yasak ya da kanun dışı ürünleri göndermek için kullanamaz. Gönderilen paketlerle ilgili doğabilecek her türlü yasal sorun ve bu sorunlardan kaynaklanabilecek cezalar tamamen kullanıcının sorumluluğu altındadır. `
        },
        {
          id: 17,
          text: `Amerikadaniste.com, kontrolü dışındaki hizmet kesintilerinde (Elektrik kesilmesi, haberleşme şebekesi ya da uydu bağlantı kesintileri vb.) Amerikadaniste.com servislerinin kullanılamaması nedeniyle dosya, bilgi ya da belge kaybından dolayı hiçbir şekilde sorumlu tutulamaz.`
        },
        {
          id: 18,
          text: `Amerikadaniste.com aracılığıyla, kimyasal malzemeler, silah, patlayıcı maddeler, nakit para, döviz, çek, senet, mücevher ve tekrar satılmak üzere ticari nitelikte ürünler ve Gümrük Müsteşarlığı'nın hızlı kargo taşımacılığıyla ilgili 19.04.2011 tarihinde yayınladığı genelgeyle yapılan kısıtlamalar neticesinde tütün ve tütün ürünleri, alkollü ürünler, gıda ürünleri, cep telefonları, takviye edici gıdalar, sporcu gıdaları ve kozmetik ürünleri gönderilememektedir. Ayrıca uluslararası kargo kurallarında 2010 yılının Aralık ayında yapılan değişiklikler nedeniyle 500 gramdan daha ağır olan yazıcı kartuşları ve tonerler de Amerikadaniste.com aracılığıyla gönderilemez.`
        },
        {
          id: 19,
          text: `Amerikadaniste.com, kullanım şartlarını değiştirme, kaldırma ya da iptal etme hakkını saklı tutar. Lütfen kullanım şartlarını belirli aralıklarda gözden geçiriniz. Siteyi kullanmaya devam etmeniz, güncellenen kullanım şartlarını da kabul etmeniz anlamına gelecektir.`
        },
        {
          id: 20,
          text: `Amerikan kanunlarına göre suç sayılabilecek herhangi bir kullanım sonucu oluşabilecek yasal yükümlülüklerin tamamı Amerikadaniste.com kullanıcısına aittir.`
        },
        {id: 21, text: `Üyelik iptali için taraflardan birinin yazılı talebi gelmedikçe üyelik aktif kalır.`},
      ],
      ActivationTexts: {
        Activation: `Üyelik adımlarını tamamlamaya çok az kaldı. Bu sayfada kredi kartı bilgileri, tamamen kimlik doğrulama 
        amacıyla talep edilmektedir. Bu işlem sırasında sizlerden kesinlikle herhangi bir ücret talep edilmemektedir. Sadece kimlik doğrulaması için bu aşamada kartınızdan $1 işlem gerçekleştirilecek ve bu tutar aynı anda kartınıza iade edilecektir.`,
        Plan: `Amerikadaniste servislerinden memnun kalırsanız, 30 gün ücretsiz üyeliğin ardından 
                hangi üyelik paketi ile devam etmek istersiniz?`
      }
    },
    EN: {
      UserAgreementText: `I have read and accept <a>Terms and Conditions.</a>`,
      UserAgreementContent: [
        {id: 0, text: `Amerikadaniste.com provides a U.S. mailing address for its users. This address is located in Clifton, NJ.`},
        {
          id: 1,
          text: `Amerikadaniste.com receives members' mail and packages at U.S. Logistics Center, and forwards them to members' local addresses during business hours.`
        },
        {
          id: 2,
          text: `Amerikadaniste.com member is responsible for any charges for receiving the mail and packages, any taxes or custom fees and any other related charges.`
        },
        {
          id: 3,
          text: `Amerikadaniste.com member authorizes Legrin LLC to charge his/her credit card for the above mentioned taxes, shipping charges or fees.`
        },
        {
          id: 4,
          text: `Shipping charges are based on weight and volume of the package and will be calculated before member confirms shipment by clicking the "Confirm Order" button.`
        },
        {
          id: 5,
          text: `Amerikadaniste.com stores members' mail and packages for 60 days for free. Storages extended 60 days are charged at $2.5 per lbs per month until 90 days. Amerikadaniste.com can not store your mail or packages for more than 90 days. A storage notice will be sent for packages that exceed 90 days. 5 business days after this notice, Amerikadaniste.com has the right to the disposal of the packages stored for more than 90 days. Creating the cargo order including packages that exceed 60 days of free storage is impossible without the charge of storage.`},
        {
          id: 6,
          text: `Cargo rate calculation is based on the pure weight of the packages. Volumetric weight can also be taken in consideration for the boxes. Volumetric weight is being calculated by multiplying the dimensions (length, height and width in inches) of the packages and dividing the result by 139. If the volumetric weight is higher than the physical weight, shipping rate will be calculated over the volumetric weight.`
        },
        {
          id: 7,
          text: `Shipping insurance is calculated based on the declared value of the item. Insurance costs are shown to member before the member confirms the shipment by clicking on the "Confirm Order" button.`
        },
        {id: 8, text: `Amerikadaniste.com (Legrin LLC) opens, inspects and repacks all packages received for security purposes.`},
        {
          id: 9,
          text: `Both parties have the right to cancel this agreement via written notice (e-mail, fax message or mail). If an annual membership is cancelled, the unused months will be refunded as $6/per month. The refund will be completed within 10 business days.`
        },
        {
          id: 10,
          text: `The monthly membership fees can not be refunded in case of cancellation. Amerikadaniste.com has the right to send the packages back to sellers or dispose them. The shipping rates of the packages will be double of normal rates for the users who cancelled their membership and want to ship packages after cancellation.`
        },
        {
          id: 11,
          text: `If the user does not make the payment over 30 days, for the Amerikadaniste.com services that he/she used, Amerikadaniste.com keeps the right to cancel the user’s membership. Amerikadaniste.com has the right to send the packages back to sellers or dispose them. In this case, user would not have the option to claim a right over these packages.`
        },
        {
          id: 12,
          text: `Monthly and yearly memberships automatically renews unless the member asks for membership cancellation. The monthly membership fee is $9 and the yearly membership fee is $75.`
        },
        {
          id: 13,
          text: `Overdue fees: There is $10 overdue fee and %8 interest for late payment over 30 days of the due date of the services used. The interest will be charged for the each month that the payment not made.`
        },
        {
          id: 14,
          text: `The membership of Amerikadaniste.com starts with the registration. The use of US mailing address provided by Amerikadaniste.com means that the member accept the terms and conditions and authorizes Amerikadaniste.com to charge member's credit card for all the services and fees occured on Amerikadaniste.com.`
        },
        {
          id: 17,
          text: `Amerikadaniste.com user, as becoming a member of Amerikadaniste.com, agrees to get marketing promotions, campaign bulletins and all kinds of information and communication about the service by electronic mail to the registered e-mail address, and SMS to registered cell phone number. If the user wants, after logging in to his/her Amerikadaniste.com account, he/she can change his/her preferences by removing the tick in the boxes of "Promotional mailing" and/or "SMS notification" on "My Account" page or he/she can contact Amerikadaniste.com by email or phone.`
        },
        {
          id: 16,
          text: `Amerikadaniste.com member agrees to obey all the laws and regulation of both U.S. and his/her country. Amerikadaniste.com member can not and will not use this site for fraudulent transactions or shipments or for shipping illegal or controlled substances. Amerikadaniste.com member will be responsible for any charges or fees resulting from a violation of this condition.`
        },
        {
          id: 17,
          text: `Amerikadaniste.com is not liable for not fulfilling any obligations towards members at all as a result of circumstances beyond our control such as (but not limited to); acts of god including earthquakes, cyclones, storms, flooding, fire, disease, fog, snow or frost; force majeure including (but not limited to) war, accidents, acts of public enemies, strikes, embargoes, perils of the air, local disputes or civil commotions; national or local disruptions in air or ground transportation networks and mechanical problems to modes of transport or machinery; latent defects or inherent vice in the contents of the shipment; criminal acts of third parties such as theft and arson; electricity blackouts, network or satellite connection breaks.`
        },
        {
          id: 18,
          text: `Vitamins, dietary supplements, cosmetics, grocery products, cell phones, cash, currency, checks, bonds, jewelry, binoculars, cigarettes and alcohol are not allowed to be shipped via express cargo into Turkey as per Turkish Customs regulations for express shipping dated 19.04.2011. Shipping chemicals, flammable, explosive and/or sharp items, weapons, goods for reselling are strictly prohibited on Amerikadaniste.com. Additionally, cartridges and toners over 500 gr can not be shipped via express cargo due to the international cargo rule amendments which took effect by December, 2010.`
        },
        {
          id: 19,
          text: `Amerikadaniste.com keeps the right to change, amend, cancel or make exceptions to the terms and conditions listed here. Please check this terms and conditions page time to time. As long as an user has a membership and/or he/she continues to use Amerikadaniste.com services means that the user accepts and is subject to these terms and conditions.`
        }
      ],
      ActivationTexts: {
        Activation: `Hello, welcome to the membership activation page. There is very little left to complete the membership steps. On this page, 
        credit card information is requested purely for identity verification purposes. There is absolutely no charge from you during this process. 
        $1 will be processed from your card at this stage for authentication only, and this amount will be refunded to your card at the same time.`,
        Plan: `If you are satisfied with the Amerikadaniste services, after 30 days of free trial, which membership package would you like to continue with?`
      }
    }
  };

  static BasketTexts = {
    TR: {
      BasketAgreementText: `<a>Kullanıcı Sözleşmesi</a>'ni okudum, kabul ediyorum.`,
      UserAgreementContent: {
        usage_conditions: [
          {
            id: 0,
            header: 'KULLANIM KOŞULLARI',
            text: `Legrin bünyesinde faaliyet gösteren “Amerikadaniste” nezdinde gerçekleştireceğiniz tüm işlemlerde, işbu kullanım koşullarında yer alan hüküm ve maddeler geçerlidir.`
          },
          {
            id: 1,
            text: `Amerikadaniste, Legrin bünyesinde; nihai kullanıcı ile satıcı arasındaki aracılık işlemlerini yürüten, satın alınan ürünün son kullanıcıya iletilme işlemlerini gerçekleştiren bir kuruluştur. Amerikadaniste üzerinden bir ürünün sipariş verilmesi için; son kullanıcıya Amerika içerisinde bir posta adresi (ürünün teslimat adresi) tahsis edilir. Tahsis edilen posta kutusuna, verilen sipariş, asıl satıcı ve asıl satıcının çalışmakta olduğu posta servisi vasıtası ile iletilir. Posta adresine temin edilen ürün, Legrin tarafından sipariş eden nihai kullanıcıya ulaştırılmak üzere, Türkiye’ye gönderilecek şekilde kargoya verilir. Burada Legrin, taşımacılık hizmeti veren bir kuruluş değil; satın alan nihai kullanıcının temsilcisi olarak Amerika Birleşik Devletleri’nde hareket eden bir aracı olarak faaliyet göstermektedir.`
          }
        ],
        ovnership: [
          {
            id: 0,
            header: 'Mülkiyet',
            text: `Satın alan nihai kullanıcı, ürünü satın alırken; satın alan tarafın bilgileri olarak kendi bilgilerini satıcıya temin edecektir. Adres ise, Amerikadaniste tarafından satın alana temin edilen posta adresi olacaktır. Satın alınan ürünün mülkiyeti, Amerikadaniste tarafından tahsis edilen posta adresine ulaştığı andan itibaren nihai alıcıya geçer. Bundan ötürü, satıcının posta adresine teslim sürecinde veya Legrin ’in kusuru olmaksızın Amerika’dan Türkiye’ye gönderim aşamasında gerçekleşecek kayıp ve ürün üzerindeki zararlardan nihai kullanıcı sorumludur. Legrin, Amerikadaniste vasıtasıyla satın alınan ürünlerin mülkiyetini, faaliyetlerin hiçbir aşamasında iktisap etmemektedir.`
          },
          {
            id: 1,
            text: `Bunun yanında, satın alınan ürünün “satım” işlemi Amerika Birleşik Devletleri içerisinde gerçekleşir ve 
          sona erer. Bu işlemi takip eden süreç, tamamen ürünün satın alana iletilmesi ve ulaştırılması sürecidir.
          Amerikadaniste ve Legrin, herhangi bir sebepten ötürü satıcı firma ile bir iş birliği içerisinde değildir
          . Satıcı firmanın, son kullanıcıyla kurduğu hukuki işlemler ve buradan doğan hukuki sıfatlar, Legrin ’ye 
          karşı öne sürülemez. Legrin, satıcı ve alıcı arasındaki hukuki ilişkiden ve bu ilişkilerden doğan haklardan
           tamamen muaftır ve bu ilişkilerin dışındadır. Legrin ve alıcı arasındaki hukuki ilişki ise, yukarıda belirtildiği gibi,
            ürünün alıcıya ulaştırılması maksadı ile kurulan bir temsil, aracılık ilişkisinden ibarettir.`
          },
          {
            id: 2,
            text: `Bunun yanında, satın alınan ürünün “satım” işlemi Amerika Birleşik Devletleri içerisinde gerçekleşir ve 
          sona erer. Bu işlemi takip eden süreç, tamamen ürünün satın alana iletilmesi ve ulaştırılması sürecidir.
          Amerikadaniste ve Legrin, herhangi bir sebepten ötürü satıcı firma ile bir iş birliği içerisinde değildir
          . Satıcı firmanın, son kullanıcıyla kurduğu hukuki işlemler ve buradan doğan hukuki sıfatlar, Legrin ’ye 
          karşı öne sürülemez. Legrin, satıcı ve alıcı arasındaki hukuki ilişkiden ve bu ilişkilerden doğan haklardan
           tamamen muaftır ve bu ilişkilerin dışındadır. Legrin ve alıcı arasındaki hukuki ilişki ise, yukarıda belirtildiği gibi,
            ürünün alıcıya ulaştırılması maksadı ile kurulan bir temsil, aracılık ilişkisinden ibarettir.`
          }
        ],
        import_export_prohibitions: [
          {
            id: 1,
            header: 'İthalat, İhracat Yasakları',
            text: `Sipariş edilerek temin edilen posta adresine gönderilen ürünün Amerika Birleşik Devletleri’nden ihracı yahut Türkiye Cumhuriyeti’ne ithali herhangi bir sebeple yasaklanmış ise iki ihtimal ortaya çıkmaktadır.`,
            subItems: [
              {
                id: 1,
                text: `Eğer satın alınan ürünün iadesi mümkün ise, satın alan nihai kullanıcıya bu durum bildirilecek ve ürünün iade edilme süreci başlatılacaktır. Bu durumda Legrin; ürünün kendisinin nezdinde saklanması, geri gönderimi ve sair giderlerinden doğan masrafları satın alandan tahsil eder.`
              },
              {
                id: 2,
                text: `Eğer ürünün iade edilmesi herhangi bir mevzuat veya düzenlemeden ötürü mümkün değil ise; ürün niteliğinin gerektirdiği şekilde,`
                , subItems: [{
                  id: 1,
                  text: `imha edilecek,`
                },
                  {
                    id: 2,
                    text: `gerekli resmî kurumlara teslim edilecek,`
                  },
                  {
                    id: 3,
                    text: `mkanlar elveriyor ise Legrin tarafından Amerika’da satılarak ürünün satışından tahsil edilen bedelden hizmet bedeli ve masraflar düşülerek üzerinde kalan tutar satın alan nihai kullanıcıya iade edilecektir.`
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            text: `Ürünü satın alan nihai kullanıcı, satın aldığı ürünün Amerika Birleşik Devletleri dışarısına ihraç edileceğini satıcıya bildirmek ve satıcının bu durum ile alakalı kendisine sunabileceği formları usulüne uygun bir biçimde doldurmakla, ürünlerin gerektirebileceği ihracat lisanslarını satıcı vasıtasıyla temin etmekle yükümlüdür. Bu yükümlülüklerin yerine getirilmemesi halinde, ihracat kısıtlamalarının doğması halinde, yukarıda belirtilen imha, resmî kurumlara teslim veya masrafları ve hizmet bedeli son kullanıcıya yansıtılmak üzere satış ihtimalleri söz konusu olacaktır.`
          }
        ],
        return_export_change_process: [
          {
            id: 1,
            header: 'İade, Değişim ve İptal Süreçleri',
            text: `Satın alınan ürünlerin satıcıları, Legrin ’den bağımsız kuruluşlardır. Legrin; satın alınan ürünün asıl satıcıdan son kullanıcıya ulaştırılması için faaliyet göstermektedir. Amerikadaniste üzerinden sipariş verilen ürünlerdeki iade, iptal ve değişim haklarınız ve bunlara ilişkin talepler satıcıya yöneltilmek durumundadır. Hiçbir şüpheye mahal vermemek adına belirtmek gerekir ki Legrin satış aracısı konumundadır, hiçbir şekilde Satıcı olarak düşünülmemelidir.`
          },
          {
            id: 2,
            text: `Satın alınan ürün, Legrin tarafından halen yola çıkartılmamışsa (kargo ve gönderim süreci tamamlanmamış ise) 
            siparişinizin Amerikadaniste üzerinden iptal edilmesi mümkündür. Bu durumda ödeme yapılmış ise, yapılan ödeme iptalden
             kaynaklı masraflar mahsup edilerek tarafınıza iade edilecektir. İptalden kaynaklı masraflar, 
            ürününü satın alınan asıl satıcıya iadesi için yapılan kargo ve sair masraflardır.`
          },
          {
            id: 2,
            text: `Satın alınan ürün, ödemeyi takiben Legrin tarafından kargoya verilmiş, 
            yola çıkartılmış ise, bu durumda iade, iptal ve değişim süreçlerinin, asıl satıcı ile 
            yürütülmesi gerekmektedir. Legrin , iade, iptal veya değişime konu olan ürünün, tüm masrafları alıcı üzerinde 
            kalmak üzere, satıcıya iletilmesinde aracılık edebilir. Ancak Legrin , iade süreci konusunda nihai kullanıcıya 
            lojistik hizmet vermek, aracılık etmek zorunda değildir. Legrin’in aracılık ve temsilden kaynaklanan sorumlulukları,
             ürünün son kullanıcıya teslim edilmesi ile tamamen sona erdiğinden ötürü, iade konusunda son kullanıcıya sunulacak 
             hizmetler ayrıca ücretlendirilebilir. İade sürecinde belirlenecek hizmet bedeli ve ücretler, ayrı bir sözleşmenin 
             konusunu teşkil edeceklerdir.`
          }
        ],
        representation_mediation: [
          {
            id: 1,
            header: 'Temsil ve Aracılık',
            text: `Legrin, Amerikadaniste vasıtasıyla sipariş verilen ürünler ile alakalı olarak, satın alan ve 
            satıcı arasında kurulan ilişkide, satın alanın temsilcisi rol ve vazifesini üstlenmektedir. Bundan ötürü satın
            alan tarafından, gerekli hallerde, Legrin ’e aracılık ve temsilden doğan yetkilerin verilmesi gerekmektedir.`,
            subItems: [
              {
                id: 1,
                text: `Taşıma evraklarının satın alan adına düzenlenmesi`
              },
              {
                id: 2,
                text: `Menşe Ülke Formlarının düzenlenmesi`
              },
              {
                id: 3,
                text: `Taşıma faturalarının satın alan adına ve hesabına düzenlenmesi,`
              },
              {
                id: 4,
                text: `Satın alan adına hizmet sözleşmesi kurabilme yetkisinin verilmesi,`
              }
            ]
          },
          {
            id: 1,
            text: `Satın alınan ürünün satın alana iletilebilmesi için gerekli olabilecek durumlardır. 
            Bu durumların ortaya çıkması halinde, gerekli yetkilendirme, usulünce satın alan tarafından yapılmalıdır.
             Satın alanın, sözleşmenin ifası için gerekli olan yetkilendirmeyi usulüne uygun olarak yapmayı reddetmesi,
             imtina etmesi veya ihmal etmesi halinde, satın alan sözleşmenin tamamlanması iradesinden vazgeçmiş olur ve 
             satın aldığı ürünün mülkiyetini Legrin’e bila bedel olmak üzere devir ve temlik etmiş olarak kabul edilir.`
          },
          {
            id: 2,
            text: `Legrin tarafından, satın alınan ürünlerin Amerika’da teslim edilmesi için temin edilen posta adresi Amerikadaniste hizmetleri kapsamında müşterilere tahsis edilen posta adresleri Amerikadaniste tarafından verilen hizmetler dışında herhangi bir maksatla kullanılamaz ve yerleşim yeri, ikametgah adresi vs. suretle gösterilemez. Temin edilen posta adresinin Legrin ile kurulan/kurulacak bir sözleşme haricinde kullanılması halinde, posta adresini usulsüz olarak kullanan taraf, bu kullanımın Legrin üzerinde açtığı zararları tazmin etmekle ve bu kullanımdan kendilerinin kazandığı yararları Legrin’e teslim etmekle yükümlüdür.`
          }
        ],
        last_provisions: [
          {
            id: 1,
            header: 'Son Hükümler',
            text: `Amerikadaniste üzerinden ürün satın alınması; Legrin ile bu hizmetler kapsamında hukuki muamelelere girilmesi, 
            Legrin ve satın alan taraf arasında bir ortaklık, iş birliği veya diğer bir hukuki ilişkinin varlığını göstermemektedir.
             Legrin ile kurulan hukuki muamelelerin kapsamı, yalnızca satın alanın satın aldığı ürünün, kendisine iletilmesi 
             hizmetlerinden ve bu hizmetten kaynaklanan yan ilişkilerden müteşekkildir. Başka herhangi bir hukuki ilişkinin
              varlığına yorulamaz, dayanak edilemez.`
          },
          {
            id: 2,
            text: `İşbu hükümlerin, herhangi bir sebepten ötürü mevzuatlar veya düzenlemeler ışığında geçersiz kabul edilmesi halinde, geçersiz kılınan sözleşme hükmü, hüküm ve sonuçları ile birlikte ortadan kalkar. Ancak bu durum, sözleşmenin kalan maddelerinin geçerliliğini etkilemeyecektir. Geçersiz kılınan hüküm haricindeki hükümler, geçerli bir biçimde devam ederler.`
          },
          {
            id: 3,
            text: `İşbu sözleşmenin uygulanmasından veya uygulanmamasından doğabilecek tüm uyuşmazlıklara uygulanacak hukuk New Jersey Eyalet Hukuku olup uyuşmazlıkların çözümünde Amerika Birleşik Devletleri’nin New Jersey eyaletinde yer alan mahkemeler yetkilidir.`
          }
        ]
      },


    },
    EN: {
      UserAgreementText: `I have read and accept <a>Terms and Conditions.</a>`,
      UserAgreementContent: {
        usage_conditions: [
          {
            id: 0,
            header: 'TERMS OF USAGE KOŞULLARI',
            text: `The provisions and clauses in these terms of usage apply to all transactions you make with "Amerikadaniste" operating under Legrin.`
          },
          {
            id: 1,
            text: `Amerikadaniste is an organisation that is Legrin's subsidiary which responsible for mediations between vendors and end users along with all deliviring processes of purchased stuffs to customer. A postal adress in USA (delivery adress of the product) is assigned to the end user to order a product from US via Amerikadaniste. The orders are transferred to the assigned postal adress by vendor through their carrier service. The product sent to the postal adress is headed over shipping company by Legrin in order to deliver to end user. In here, Legrin has not got any transportation services but operates as an intermediary acting in the United States as a representative of end user.`
          }
        ],
        ovnership: [
          {
            id: 0,
            header: 'Ownership',
            text: `When end user makes purchasing from seller, end user provide its own information to the seller as the purchaser's information. The adress will be the postal adress provided by Amerikadaniste. Once the product purchased from vendor, ownership of the purchased product passes to the end user. Because of that, end users responsible for any damages during delivering to the postal adress or in transformation from USA to Turkey without any problem related Legrin. Legrin does not acquire ownership of any product purchased through Amerikadaniste at any stage of its activities.`
          },
          {
            id: 1,
            text: `Besides, buying transactions of the purchasing product takes place and ends within United States of America. The process followes that transaction, it is completely transportation of this product to end user. Amerikadaniste and Legrin are not cooperation with vendor with any reason. The legal transactions established by the seller company with the end user and the legal attributes arising from this cannot be put forward against Legrin. Legrin is excluded the legal relationship between the vendor and end user and the rights arising from these relationship. Therefore Legrin is completely exempt from these relationship. The legal relationship between vendor and Legrin consists of a representation and mediation relationship established with the aim of delivering the product to the end user as stated above.
`
          }
        ],
        import_export_prohibitions: [
          {
            id: 1,
            header: 'Import and Export Prohibitions',
            text: `If exporting from USA or importing to Turkey those products which were sent to Turkey postal adress purchased, is forbidden because of any reason, there will bring about two different scenario.`,
            subItems: [
              {
                id: 1,
                text: `If the purchased products If it is possible to return the purchased product, this circumstance will be informed end user and the return process of this product will be initiated.In this case Legrin; collects the costs arising from the storage ,return, and other expenses of the product from end user.`
              },
              {
                id: 2,
                text: `If return of the product is not possible due to any legislation or regulation ; as required by the nature of the product,`
                , subItems: [{
                  id: 1,
                  text: `to be destroyed,`
                },
                  {
                    id: 2,
                    text: `to be delivered to the necessary official institutions,`
                  },
                  {
                    id: 3,
                    text: `to be sold in the United states by Legrin, if possible,and the remaining amount will be refunded to the end user by deducting the service fee and costs from the sale of the product will be.`
                  }
                ]
              }
            ]
          },
          {
            id: 2,
            text: `End user purchasing the product, is obligated to inform the vendor that the product purchased will be exported outside of United States, and dully fill out the forms that the vendor can submit to end user regarding this situation, and to obtain the export licenses that the products may require through the vendor. If these obligations are not fulfilled, in case of export restrictions, there will be the possibility of destruction, delivery to official institutions or sales to reflect the costs and service fee to the end user.`
          }
        ],
        return_export_change_process: [
          {
            id: 1,
            header: 'Return, Exchange and Cancellation Processes',
            text: `Vendors of purchased product are independent from Legrin. Legrin operates the transmission of the purchased product from vendors to end user. End user's rights to return, cancel and change the products ordered through Amerikadaniste and the requests related to them must be directed to the vendor. For the avoidance of any doubt, it should be noted that Legrin is a sales agent and should not be considered as a vendor in any way.`
          },
          {
            id: 2,
            text: `If the purchased product is still not shipped by Legrin (if the shipping and shipping process is not completed), it is possible to cancel your order through Amerikadaniste. In this case,if the payment has been made , the payment will be refunded to you by deducting the costs arising from the cancellation. Expenses arising from cancellation are shipping and other costs incurred for the return of the product to the vendor.`
          },
          {
            id: 2,
            text: `Following the payment process in case purchased consumings were shipped by Legrin or has been on the way, cancellation,return or exchanging processed must be carried out with main sellers. If the customers willing to pay all expenditures related to these transactions, Legrin will assist for mediation operations. However, Legrin doesn't have to give Logistic services or mediate for those. Since the responsibilities of Legrin come from representation and mediation service will be entirely finished by delivered to end users, the services as regards return process will be charged additionally. The fee and service prices in return process, will constitute in another contract.`
          }
        ],
        representation_mediation: [
          {
            id: 1,
            header: 'Representation and Mediation',
            text: `Legrin, pertaining to products ordered by Amerikadaniste, represents buyers in relations between customer and seller. For that reason, in required circumstances it is necessary that Legrin should be authorized by buyers in terms of representation and mediation.`,
            subItems: [
              {
                id: 1,
                text: `Issuing transport documents to the name of the purchaser,`
              },
              {
                id: 2,
                text: `Organizing the Country of Origin Forms,`
              },
              {
                id: 3,
                text: `Preparation of transportation invoices to the name and account of the purchaser,`
              },
              {
                id: 4,
                text: `Authorization to establish a service contract on behalf of the purchaser,`
              }
            ]
          },
          {
            id: 1,
            text: `These conditions above are required in order to deliver purchased goods to buyers. If these situations arise, the necessary authorization must be duly made by the purchaser. In case the buyer refuses, neglects the authorization required for the performance of the contract, the purchasers relinquishes their will to complete that and is deemed to have transferred and assigned the ownership of the purchased product to Legrin, even for a price.`
          },
          {
            id: 2,
            text: `The postal address provided by Legrin for the delivery of purchased products in the USA cannot be used for any other purpose, except for purchases made through USA. In case the provided postal address is used outside of a contract established / to be established with Legrin, the party using the postal address illegally is obliged to compensate the damages caused by this use, on Legrin and to deliver the benefits gained by them from this use to Legrin.`
          }
        ],
        last_provisions: [
          {
            id: 1,
            header: 'Last Provisions',
            text: `Buying a product through Amerikadaniste, being a part of legal interactions within the scope of those services with Legrin does not indicate the existence of a partnership, cooperation or other legal relationship between Legrin and the purchaser. The scope of legal transactions established with Legrin, covers only of shippings the purchased products to customers and the side relations arising from the service. It cannot be attributed to the existence of any other legal relationship.`
          },
          {
            id: 2,
            text: `In the event that these provisions are deemed invalid in the light of legislation or regulations for any reason, the invalidated contractual provision, together with its terms and consequences, shall cease to exist. However, this will not affect the validity of the remaining articles of the contract. Provisions other than the overridden provision continue to be valid.`
          },
          {
            id: 3,
            text: `The law to be applied to all disputes that may arise from the implementation or non-implementation of this contract is New Jersey State Law and the courts located in the state of New Jersey of the United States of America are authorized to resolve the disputes.`
          }
        ]
      },
      ActivationTexts: {
        Activation: `Hello, welcome to the membership activation page. There is very little left to complete the membership steps. On this page, 
        credit card information is requested purely for identity verification purposes. There is absolutely no charge from you during this process. 
        $1 will be processed from your card at this stage for authentication only, and this amount will be refunded to your card at the same time.`,
        Plan: `If you are satisfied with the Amerikadaniste services, after 30 days of free trial, which membership package would you like to continue with?`
      }
    }
  };
}

